import { useEffect } from "react";
import { useUserContext } from "../context/UserContext";

const useSubscribeMqtt = (sessionId, handleReceiveMessage) => {
  const { mqttClient } = useUserContext();

  useEffect(() => {
    subscribeMessageListener();

    return () => {
      unsubscribeMessageListener();
    };
  }, []);

  useEffect(() => {
    if (sessionId == "") return;

    mqttClient.subscribe(sessionId, (err) => {
      if (!err) {
        console.log("Subscribed to session => " + sessionId);
        return;
      }

      console.error("Error while subscribing to => " + sessionId);
    });

    return () => {
      mqttClient.unsubscribe(sessionId, (err) => {
        if (err) {
          console.error("Error while unsubscribing from => " + sessionId);
          console.error(err);
          return;
        }

        console.log("Unsubscribed from session id => " + sessionId);
      });
    };
  }, [sessionId]);

  const subscribeMessageListener = () => {
    mqttClient.on("message", (topic, message) => {
      const payload = JSON.parse(message.toString());

      handleReceiveMessage(payload);
    });
  };

  const unsubscribeMessageListener = () => {
    mqttClient.off("message", (err) => {
      if (err) {
        console.error("Error while unsubscribing message event");
        return;
      }
    });
  };

  const sendMessage = (message) => {
    if (typeof message != "object") {
      console.error("Message type must be JSON object");
      return;
    }

    mqttClient.publish(sessionId, JSON.stringify(message));
  };

  return { sendMessage };
};

export default useSubscribeMqtt;
